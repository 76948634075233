var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "errorContainer",
        { attrs: { error: _vm.erreur, warning: _vm.warning } },
        [
          _vm.Licenses
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { md: "12" } }, [
                    _c(
                      "h1",
                      {
                        class: [
                          `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                          ,
                          { "is-pwa": _vm.$isPwa() },
                        ],
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(this.caption4) + "\n\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", xl: "12" } },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "slide" } },
                        [
                          _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-card-body",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _vm.$screen.width >= 992
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "my-1",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  staticClass: "mb-0",
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1,
                                                      "Filter"
                                                    ),
                                                    "label-cols": "3",
                                                    "label-align-sm": "left",
                                                    "label-size": "sm",
                                                    "label-for": "filterInput",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    { attrs: { size: "sm" } },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "search",
                                                          id: "filterInput",
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              20,
                                                              "Type to Search"
                                                            ),
                                                        },
                                                        model: {
                                                          value: _vm.filter,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.filter = $$v
                                                          },
                                                          expression: "filter",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.filter,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.filter =
                                                                      ""
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-times",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            cols: `${
                                              _vm.$screen.width >= 992 ? 6 : 12
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              class: `${
                                                _vm.$screen.md
                                                  ? "pull-right"
                                                  : ""
                                              }`,
                                            },
                                            [
                                              _c("b-button", {
                                                attrs: {
                                                  block: !_vm.$screen.md,
                                                  size: "lg",
                                                  variant: "primary",
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.FormMSG(
                                                      2,
                                                      "New license"
                                                    )
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.newLicense()
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", xl: "12" } },
                                        [
                                          _vm.$screen.width < 992
                                            ? _c("CardListBuilder", {
                                                attrs: {
                                                  items: _vm.Licenses,
                                                  fields: _vm.accLicenses,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "actions",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant: "none",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.editLicense(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS.EDIT
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .EDIT
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant: "none",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delItem(
                                                                      data.item
                                                                        .id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .TRASH.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .TRASH
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  789404948
                                                ),
                                              })
                                            : _vm._e(),
                                          _vm.$screen.width >= 992
                                            ? _c("b-table", {
                                                ref: "myTable",
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                                attrs: {
                                                  hover: _vm.hover,
                                                  responsive: "sm",
                                                  "sticky-header": "700px",
                                                  selectable: "",
                                                  selectedVariant:
                                                    _vm.selectedColor,
                                                  "select-mode": _vm.selectMode,
                                                  items: _vm.Licenses,
                                                  fields: _vm.accLicenses,
                                                  "current-page":
                                                    _vm.currentPage,
                                                  filter: _vm.filter,
                                                  "per-page": _vm.perPage,
                                                  bordered: "",
                                                  small: "",
                                                  "head-variant": _vm.hv,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "cell(view)",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant: "none",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.editLicense(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS.EDIT
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .EDIT
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "cell(rem)",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant: "none",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delItem(
                                                                      data.item
                                                                        .id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .TRASH.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .TRASH
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4287166210
                                                ),
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-modal",
                                    {
                                      staticClass: "modal-success",
                                      attrs: {
                                        "header-class":
                                          "header-class-modal-doc-package",
                                        title: _vm.FormMSG(60, "DELETE ?"),
                                        "ok-variant": "Delete",
                                        "modal-class": "mui-animation",
                                        fade: false,
                                      },
                                      on: {
                                        ok: function ($event) {
                                          return _vm.delItemConfirmed()
                                        },
                                      },
                                      model: {
                                        value: _vm.successModal,
                                        callback: function ($$v) {
                                          _vm.successModal = $$v
                                        },
                                        expression: "successModal",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            this.FormMSG(
                                              61,
                                              "Are you sure you want to delete this item?"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _c("loading", {
                                    attrs: {
                                      active: _vm.isLoading,
                                      "is-full-page": true,
                                    },
                                    on: {
                                      "update:active": function ($event) {
                                        _vm.isLoading = $event
                                      },
                                    },
                                  }),
                                  this.Licenses.length > this.perPage &&
                                  _vm.$screen.width >= 992
                                    ? _c(
                                        "nav",
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { xl: "5" } },
                                                [
                                                  _c("b-pagination", {
                                                    attrs: {
                                                      size: "sm",
                                                      "total-rows":
                                                        _vm.getRowCount(
                                                          this.Licenses
                                                        ),
                                                      "per-page": _vm.perPage,
                                                      "prev-text": "<-",
                                                      "next-text": "->",
                                                      "hide-goto-end-buttons":
                                                        "",
                                                    },
                                                    model: {
                                                      value: _vm.currentPage,
                                                      callback: function ($$v) {
                                                        _vm.currentPage = $$v
                                                      },
                                                      expression: "currentPage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }